import { useLocalStorage, usePreferredLanguages } from '@vueuse/core';
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { updateDoc } from 'firebase/firestore';
import { useDocument } from 'vuefire';
import { useRoute } from 'vue-router';

function computeScore(lang: string, preferred: string) {
  if (lang === preferred) return 2;
  if (lang.startsWith(preferred)) return 1;
  return 0;
}

export function usePreferredLocale() {
  const authStore = useAuthStore();
  const i18n = useI18n();
  const route = useRoute();
  const localStorageLang = useLocalStorage<string | undefined>('language', undefined);
  const userSnap = useDocument(computed(() => authStore.userAccountReference));
  const preferredLanguages = usePreferredLanguages();

  const localeFromDb = computed(() => {
    if (!userSnap?.data?.value) return undefined;

    const language = userSnap?.data.value?.Language ?? 'de';
    const country = userSnap?.data.value?.CountryCode ?? 'CH';
    return `${language}-${country}`;
  });

  const localeFromLocalStorage = computed(() => {
    if (localStorageLang.value) {
      if (localStorageLang.value.split('-').length === 1) return localStorageLang.value + '-' + 'CH';
      return localStorageLang.value;
    }
    return undefined;
  });

  const localeFromPreferredLanguages = computed(() => {
    const availableLocales = i18n.availableLocales;

    const scores = availableLocales.map((locale) =>
      preferredLanguages.value.reduce((acc, preferred) => acc + computeScore(locale, preferred), 0),
    );

    return availableLocales[scores.indexOf(Math.max(...scores))];
  });

  const localeFromRoute = computed(() => {
    return route.query.lang as string | undefined;
  });

  const locale = computed(() => {
    return (
      localeFromRoute.value ??
      localeFromDb.value ??
      localeFromLocalStorage.value ??
      i18n.locale.value ??
      localeFromPreferredLanguages.value ??
      'de-CH'
    );
  });

  const language = computed({
    get: () => locale.value.split('-')[0],
    set: (value: string): Promise<void> => setLocale(value + '-' + countryCode.value),
  });

  const countryCode = computed({
    get: () => locale.value.split('-')[1] ?? 'CH',
    set: (value: string): Promise<void> => setLocale(language.value + '-' + value),
  });

  const currencySymbol = computed(() => {
    try {
      const numFormat = i18n.numberFormats.value[locale.value];
      const formatter = new Intl.NumberFormat(locale.value, numFormat.currency);
      return formatter.formatToParts(12345.6).find((part) => part.type === 'currency')?.value ?? 'CHF';
    } catch (e) {
      console.log(e);
      return 'CHF';
    }
  });

  async function setLocale(locale: string) {
    localStorageLang.value = locale;
    if (authStore.isLoggedIn) {
      await updateDoc(authStore.userAccountReference!, {
        Language: locale.split('-')[0],
        CountryCode: locale.split('-')[1] ?? 'CH',
      });
    }
    i18n.locale.value = locale;
    document.documentElement.lang = locale;
  }

  return {
    locale,
    language,
    countryCode,
    currencySymbol,
    setLocale,
  };
}
